<template>
  <div
    class="goals__key-result"
    :class="{ 'goals__key-result-complete': complete }"
  >
    <p class="key-result-name" :id="'key-result-id-' + id" contenteditable @focusout="updateKeyResultName">{{ name }}</p>
    <template v-if="complete">
      <p class="key-result-complete-message" @click="$emit('set-not-complete')">Complete!</p>
    </template>
    <template v-else>
      <IconNew name="key-result-check" class="key-result-complete-check" @click="$emit('set-complete')"/>
    </template>
  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";
export default {
  name: "KeyResultsSmall",
  components: {IconNew},
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    complete: {
      type: [Boolean, undefined],
      required: true
    }
  },
  setup (props, {emit}) {
    const updateKeyResultName = function () {
      emit('update-key-result-name')
    }

    return {
      updateKeyResultName
    }
  }
};
</script>

<style scoped lang="scss">
.goals__key-result {
  margin-top: 8px;
  padding: 8px;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  box-sizing: border-box;
  background: #EEF0F5;
  border: 1px solid #E3E5EC;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: black;

}

.goals__key-result-complete {

  background-color: rgba(12, 172, 96, 0.1);
  border: 1px solid rgba(12, 172, 96, 0.2);

  .key-result-name {
    text-decoration: line-through;

  }
}

.key-result-complete-message {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: right;

  color: #0CAC60;
  align-self: center;
  cursor: pointer;

}

.key-result-complete-check {
  height: 12px;
  width: 12px;
  align-self: center;
  cursor: pointer;
}

.key-result-name {
  width: calc(100% - 95px);
  flex-grow: 1;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus:hover {
    cursor: text;
  }
}
</style>
