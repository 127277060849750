<template>
  <div class="pxp__goals">

    <h1 class="goals__title">Goals</h1>

    <template v-if="status === 'load'">

    </template>
    <template v-else-if="status === 'ok' && goals.length + risks.length > 0">
      <div class="goals__current-goals">
        <h2 class="black">Current goals</h2>

        <template v-if="goals.length > 0">

          <template v-for="(goal) in goals" :key="'goal-id-' + goal.id">
            <GoalsCard :goal="goal"
                       class="goals__card"
                       @click=""
                       :workspace="workspaceId"
                       @delete-card="deleteCard(goal.id)"/>
          </template>


        </template>

        <template v-else>
          <div class="empty-goals">
            <div class="empty-content-wrapper">
              <IconNew name="goals-empty-icon" class="icon"/>
              <p class="secondary-text">No goals added</p>
            </div>

          </div>
        </template>
        <ButtonNew size="l" text="New goal" mode="ghost" class="goals-button" @click="openModalCreate = true"/>

      </div>

      <div class="goals__line"/>

      <div class="goals__possible-risks">
        <h2 class="black">Possible risks</h2>


        <template v-if="risks.length > 0">

          <template v-for="risk in risks" :key="'goal-id-' + risk.id">
            <GoalsCard :goal="risk"
                       class="goals__card"
                       @click=""
                       :workspace="workspaceId"
                       @delete-card="deleteCard(risk.id)"/>
          </template>

        </template>

        <template v-else>
          <div class="empty-risks">
            <div class="empty-content-wrapper">
              <IconNew name="goals-bomb" class="icon"/>
              <p class="secondary-text">No risks added</p>
            </div>

          </div>
        </template>
        <ButtonNew size="l" text="New risk" mode="ghost" class="goals-button" @click="openRiskModalCreate = true"/>


      </div>

    </template>

    <template v-else-if="status !== 'error'">
      <div class="empty-all">
        <IconNew name="goals-empty-icon" class="icon"/>
        <h1 class="light">Let’s set your goals</h1>
        <ButtonNew
            mode="text"
            icon-name="video"
            text="How to set goals using OKRs?"
            size="s"
            icon-position="left"
            @click="showVideo = true"
        />
        <p class="secondary-text">OKR (objectives and key results) model helps get your head straight, <br>
          reach your PhD easier and identify possible risks.</p>
        <ButtonNew mode="green" text="Create goal" size="l" class="button" @click="openModalCreate = true"/>
      </div>
    </template>

    <Modal id="new-goal" is-center header footer :show="openModalCreate">
      <template v-slot:header>
        <div class="new-goal-header">
          <h1>New Goal</h1>
        </div>
      </template>
      <div class="new-goal-body">

        <h2>Objective</h2>
        <TextArea placeholder="Keep it short" name="new-goal-objective" id="new-goal-objective" v-model="newGoalObjective"/>
        <h2>Key results (optional)</h2>
        <template v-for="(kr, index) in newGoalKeyResults" :key="'new-goal-kr-' + index">
          <TextInput :name="'new-goal-kr-' + index + 'ti'" :id="'new-goal-kr-' + index + 'ti'" v-model="newGoalKeyResults[index].name" :placeholder="'Key result #' + (index+1)" type="text"/>
        </template>

        <Button text="+ Add key result" style-modifiers="text" class="new-goal-add-key" @click="addNewKeyResult"/>
      </div>


      <template v-slot:footer>
        <div class="new-goal-footer">

          <ButtonNew mode="green" text="Create goal" size="l" @click="addCard" :loading="statusNewGoal === 'load'"/>
        </div>
      </template>

    </Modal>

    <Modal id="new-risk" is-center header footer :show="openRiskModalCreate">
      <template v-slot:header>
        <div class="new-goal-header">
          <h1>New Risk</h1>
        </div>
      </template>
      <div class="new-goal-body">

        <h2>Risk</h2>
        <TextArea placeholder="Keep it short" name="new-goal-objective" id="new-goal-objective" v-model="newGoalObjective"/>
        <h2>How to avoid (optional)</h2>
        <template v-for="(kr, index) in newGoalKeyResults" :key="'new-goal-kr-' + index">
          <TextInput :name="'new-goal-kr-' + index + 'ti'" :id="'new-goal-kr-' + index + 'ti'" v-model="newGoalKeyResults[index].name" :placeholder="'How to avoid #' + (index+1)" type="text"/>
        </template>

        <Button text="+ Add how to avoid" style-modifiers="text" class="new-risk-add-key" @click="addNewKeyResult"/>
      </div>


      <template v-slot:footer>
        <div class="new-goal-footer">

          <ButtonNew mode="green" text="Create risk" size="l" @click="addCard" :loading="statusNewGoal === 'load'"/>
        </div>
      </template>

    </Modal>

    <Modal
        id="goals-video-OKR"
        v-model:show="showVideo"
        is-center
        class="video-modal"
    >
      <video
          src="https://res.cloudinary.com/dewnqhcdc/video/upload/v1607915632/video/Goals_alztmf.mp4"
          width="1920"
          height="1080"
          frameborder="0"
          uk-video="autoplay: inview"
          uk-responsive
      ></video>
    </Modal>

  </div>
  <div class="partner">
    <img :src="require('@/assets/images/course/footer-logo.png')" alt="logo">
  </div>

</template>

<script>
import {createGoal, deleteGoal, getAllGoals} from "@/api/goals";
import GoalsCard from "@/components/Goals/GoalsCard";
import {computed, inject, reactive, ref, toRefs, watch} from "vue";
import TextInput from "@/components/UI-Kit/TextInput";
import TextArea from "@/components/UI-Kit/TextArea";
import Button from "@/components/UI-Kit/Button";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import Modal from "@/components/UI-Kit/Modal";
import IconNew from "@/components/UI-Kit/IconNew";

const addNewGoalSetup = function (props) {
  const newGoalObjective = ref("")
  const newGoalKeyResults = reactive([])
  const statusNewGoal = ref("close")
  const openModalCreate = ref(false)
  const openRiskModalCreate = ref(false)
  const type = computed(() => openRiskModalCreate.value? 'risk': 'goal')


  const getNewGoal = function () {
    statusNewGoal.value = 'load'
    return {
      name: newGoalObjective.value,
      keyResult: [...newGoalKeyResults],
      type: type.value
    }
  }

  const toEmpty = function () {
    newGoalObjective.value = ""
    newGoalKeyResults.splice(0, newGoalKeyResults.length)
    openModalCreate.value = false
    openRiskModalCreate.value = false
    statusNewGoal.value = 'ok'
  }

  const addNewKeyResult = function() {
    newGoalKeyResults.push({
      name: "",
      details: "",
      complete: false
    })
  }

  const removeNewKeyResult = function(index) {
    newGoalKeyResults.splice(index, 1)
  }

  return {
    addNewKeyResult,
    removeNewKeyResult,
    getNewGoal,
    toEmpty,
    newGoalObjective,
    newGoalKeyResults,
    statusNewGoal,
    openModalCreate,
    openRiskModalCreate
  }

}

const goalsInitialSetup = function(props) {
  const goals = reactive([]);
  const risks = reactive([]);
  const status = ref('load');
  const goalsRefs = computed(() => toRefs(goals));
  const risksRefs = computed(() => toRefs(risks));
  const socket = inject('socket');
  const user = inject('user');
  const showVideo = ref(false)

  const newGoalSetup = addNewGoalSetup()


  socket.value.on('deleted_goal', (data) => {
    const id = data.data

    if (data.author !== user.id) {
      let ind = goals.findIndex(item => item.id === id)
      if (ind === -1) {
        ind = risks.findIndex(item => item.id === id)
        if (ind !== -1)
          risks.splice(ind, 1)
      } else {
        goals.splice(ind, 1)
      }
    }
  })

  socket.value.on('created_new_goal', (data) => {

    const newGoalData = data.data
    const authorId = data.author

    if (authorId !== user.id) {

      if (newGoalData.type === 'goal') {
        goals.push(reactive(newGoalData))
      }
      else {
        risks.push(reactive(newGoalData))
      }
    }
  })

  socket.value.on('updated_goal', (data) => {

    const goalData = data.data

    if (data.author !== user.id) {
      let ind = -1

      if (goalData.type === 'goal') {

        ind = goals.findIndex((item) => item.id === goalData.id)

        if (ind !== -1) {
          goals.splice(ind, 1, reactive(goalData))
        }
      }
      else {
        ind = risks.findIndex((item) => item.id === goalData.id)

        if (ind !== -1) {
          risks.splice(ind, 1, reactive(goalData))
        }
      }
    }
  })

  const deleteCard = function (id) {
    let ind = goals.findIndex(item => item.id === id)
    if (ind === -1) {
      ind = risks.findIndex(item => item.id === id)
      if (ind !== -1)
        risks.splice(ind, 1)
    } else {
      goals.splice(ind, 1)
    }
    deleteGoal(id, props.workspaceId)
  }

  const addCard = function () {
    const newGoal = newGoalSetup.getNewGoal()
    let ind = -1
    if (newGoal.type === 'risk') {
      ind = risks.push(newGoal) - 1
    } else {
      ind = goals.push(newGoal) - 1
    }
    createGoal(props.workspaceId, newGoal).then((data) => {
      if (newGoal.type === 'risk') {
        risks[ind] = data
      } else {
        goals[ind] = data
      }
    })
    newGoalSetup.toEmpty()
  }


  const initGoals = function () {
    status.value = 'load'

    goals.splice(0, goals.length)
    risks.splice(0, risks.length)

    getAllGoals(props.workspaceId)
        .then((goalsResponse) => {
          goalsResponse.forEach((item) => {
            if (item.type === 'goal') {
              goals.push(reactive(item))
            } else {
              risks.push(reactive(item))
            }
          })
          status.value = 'ok'
        })
        .catch(() => {
          status.value = 'error'
        })
  }

  initGoals()

  watch(() => props.workspaceId, () => {
    initGoals()
  })


  return {
    goalsRefs,
    risksRefs,
    goals,
    risks,
    status,
    deleteCard,
    addCard,
    showVideo,
    ...newGoalSetup
  };
};

export default {
  name: "Goals",
  components: {IconNew, Modal, ButtonNew, CircleLoader, Button, TextArea, TextInput, GoalsCard },
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup: function (props) {
    return {
      ...goalsInitialSetup(props),
    }
  }
};
</script>

<style scoped lang="scss">
.pxp__goals {
  background-color: #f4f5f8;
  width: 100vw;
  min-height: calc(100vh - 48px);
  margin-top: 0;
  box-sizing: border-box;
  padding: 50px;
  display: grid;
  grid-template-areas:
    "goals__title goals__title goals__title"
    "goals__current-goals goals__line goals__possible-risks";
  grid-template-columns: calc(50vw - 107px) 2px calc(50vw - 107px);
  grid-template-rows: 30px 1fr;
  grid-gap: 15px 56px;
}

.goals__card {
  margin-top: 16px;
}


.goals__title {
  grid-area: goals__title;
  justify-self: left;
}

.goals__current-goals {
  grid-area: goals__current-goals;
}

.goals__possible-risks {
  grid-area: goals__possible-risks;
}

.goals-button {
  margin: 24px auto;
  font-weight: bold!important;
}


.new-goal {
  &-header,
  &-body,
  &-footer
  {
    padding: 16px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}

.new-goal-add-key,
.new-risk-add-key {
  float: left;
  width: 120px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #0CAC60;

  opacity: 0.8;

}

.new-risk-add-key {
  width: 140px
}


.goals__line {
  display: inline-block;
  grid-area: goals__line;
  width: 2px;
  background-color: #E3E5EC;
}

.empty-all {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-items: center;
  width: calc(100vw - 100px);
  margin: auto;

  & .icon {
    width: 139px;
    height: 84px;
    margin: 0 auto;
  }
  h1 {}
  & .button {
    width: 127px;
    margin: 0 auto;
  }
  p {

  }
}
.empty-goals,
.empty-risks{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-items: center;
  height: 363px;
  border: 1px dashed #CCD0DB;
  margin-top: 16px;

  & .icon {
    width: 48px;
    height: 48px;
    margin: 0 auto;
  }
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    color: #80869D;
  }

  & .empty-content-wrapper {
    margin: auto;
  }
}

.empty-goals {
  & .icon {
    width: 80px;
  }
}
.partner {
  background-color: #f4f5f8;
}
</style>
